import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { MyBaseComponent } from '../w-core/supers/my-base.component';

@Component({
	selector: 'app-notFoundPage',
	templateUrl: './view.component.html'
})
export class NotFoundPageComponent extends MyBaseComponent {
	subscriptions: Subscription = new Subscription();
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_not_found_page_title',
			'description': 'lang_cms_text_not_found_page_description',
			'image': 'lang_cms_text_not_found_page_image'
		});
	}
	constructor() {
		super();
		this.commonService.changeMessage(true);
	}
	ngOnDestroy(): void {
		this.commonService.changeMessage(false);
		this.subscriptions.unsubscribe();
	}
}