import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/common.service';

@Pipe({name: 'wordCounter'})
export class WordCounterPipe implements PipeTransform {
	constructor(private commonService: CommonService) {}
	transform(value: string): number {
		return this.commonService.countWords(value);
	}
}

@Pipe({ name: 'mytranslate' })
export class MytranslatePipe implements PipeTransform {
	constructor(
		private commonService: CommonService) {}
	transform(value?: string): string {
		return this.commonService.myTranslateInstant(value) ?? '';
	}
}

@Pipe({name: 'lineCounter'})
export class LineCounterPipe implements PipeTransform {
	transform(value: string): number {
		var ArrObjs = value.toString().split('\n');
		return ArrObjs.length;
	}
}

@Pipe({name: 'charCounter'})
export class CharCounterPipe implements PipeTransform {
	transform(value: string): number {
		return value.length;
	}
}