import { Location } from '@angular/common';
import { Component, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { CommonService } from './w-core/services/common.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent {
	listLanguages = environment.listLanguages;
	show404Page = false;

	onActivate(): void {
		window.scroll(0, 0);
	}
	useLanguage(language: string): string{
		var curLanguage = this.translate.currentLang;
		var routerPath = this.myLocation.path().replace('/' + curLanguage, '/' + language);
		return routerPath;
	}
	isHomeRoute(): boolean{
		var curPath = this.myLocation.path();
		for(var langKey in this.listLanguages){
			if(curPath === '/' || curPath === '/' + langKey || curPath === '/' + langKey + '/'){
				return true;
			}else if(curPath.toString().indexOf('/' + langKey + '?') === 0 || curPath.toString().indexOf('/' + langKey + '/?') === 0){
				return true;
			}
		}
		return false;
	}

	//Angular life circle
	ngAfterViewInit(): void{
		let loader = this.renderer.selectRootElement('#loader');
		this.renderer.setStyle(loader, 'display', 'none');
	}
	constructor(
		private translate: TranslateService,
		private commonService: CommonService,
		private myLocation: Location,
		private renderer: Renderer2){
		this.commonService.currentMessage.subscribe(message => this.show404Page = message);
	}
}
