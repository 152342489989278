import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { SplitFileFormControl, SplitFileFormModel } from './interfaces/form-control';

@Component({
	selector: 'app-split-file',
	templateUrl: './view.component.html'
})
export class SplitFileComponent extends CToolAction<FormGroup<SplitFileFormControl>> {
	localStorageKey: string = 'splitFileForm';
	form: FormGroup<SplitFileFormControl>;

	private getDefaultData(): SplitFileFormModel{
		return {
			'isDuplicate': false,
			'isTrim': true,
			'input': '1\n2\n3',
			'number': 1,
			'start': 1,
			'fileExtension': 'txt',
			'fileName': 'name-',
		};
	}
	initFormData(): void {
		var localStorageData: SplitFileFormModel | undefined = this.commonService.myParseJson<SplitFileFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: SplitFileFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			let zip: JSZip = new JSZip();

			var inputStr = formModelObj.input;
			if(formModelObj.isTrim){
				var strResultArrObjs = inputStr.toString().split('\n');
				strResultArrObjs = this.commonService.trimDataObjs(strResultArrObjs);
				inputStr = strResultArrObjs.join('\n');
			}
			if(formModelObj.isDuplicate){
				var strResultArrObjs = inputStr.toString().split('\n');
				strResultArrObjs = this.commonService.removeDuplicates(strResultArrObjs);
				inputStr = strResultArrObjs.join('\n');
			}

			var listData = inputStr.split('\n');
			listData = this.commonService.removeEmptyLine(listData);

			var start = formModelObj.start;
			var limit = formModelObj.number;
			var startLimit = 0;
			var splitContent = '';

			listData.forEach(curData => {
				if(splitContent){
					splitContent += '\n' + curData;
				}else{
					splitContent = curData;
				}
				startLimit++;
				if(startLimit >= limit){
					var fileName = formModelObj.fileName + start + '.' + formModelObj.fileExtension;
					zip.file('split/' + fileName, splitContent);
					startLimit = 0;
					splitContent = '';
					start++;
				}
			});
			if(splitContent){
				var fileName = formModelObj.fileName + start + '.' + formModelObj.fileExtension;
				zip.file('split/' + fileName, splitContent);
			}

			zip.generateAsync({type: 'blob'}).then(function(content){
				FileSaver.saveAs(content, 'split.zip');
			});

			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_split_file_title',
			'description': 'lang_cms_text_split_file_description',
			'image': 'lang_cms_text_split_file_image'
		});
	}
	constructor() {
		super();
		this.resetWithDoAction = false;
		this.form = new FormGroup<SplitFileFormControl>({
			'isDuplicate': new FormControl(false, {nonNullable: true}),
			'isTrim': new FormControl(true, {nonNullable: true}),
			'input': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'number': new FormControl(1, {nonNullable: true, validators: [Validators.min(1), Validators.required]}),
			'start': new FormControl(1, {nonNullable: true, validators: [Validators.min(1), Validators.required]}),
			'fileExtension': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'fileName': new FormControl('', {nonNullable: true, validators: [Validators.required]})
		});
	}
}