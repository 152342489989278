import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { MergeLineFormControl, MergeLineFormModel } from './interfaces/form-control';

@Component({
	selector: 'app-merge-line',
	templateUrl: './view.component.html'
})
export class MergeLineComponent extends CToolAction<FormGroup<MergeLineFormControl>> {
	localStorageKey: string = 'mergeLineForm';
	form: FormGroup<MergeLineFormControl>;

	private getDefaultData(): MergeLineFormModel{
		return {
			'isDuplicate': false,
			'isTrim': true,
			'input': 'A\nB\nC',
			'separation': '|',
			'output': ''
		};
	}
	initFormData(): void {
		var localStorageData: MergeLineFormModel | undefined = this.commonService.myParseJson<MergeLineFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: MergeLineFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			var strResult = '';
			var listData = formModelObj.input.split('\n');
			listData = this.commonService.removeEmptyLine(listData);
			for(var i = 0; i < listData.length; i++){
				strResult += (strResult !== '' ? formModelObj.separation : '') + listData[i];
			}

			if(formModelObj.isTrim){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.trimDataObjs(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}
			if(formModelObj.isDuplicate){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.removeDuplicates(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}

			this.form.patchValue({output: strResult});
			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_merge_line_title',
			'description': 'lang_cms_text_merge_line_description',
			'image': 'lang_cms_text_merge_line_image'
		});
		this.formChanged();
		this.initFormData();
	}
	constructor() {
		super();
		this.form = new FormGroup<MergeLineFormControl>({
			'isDuplicate': new FormControl(false, {nonNullable: true}),
			'isTrim': new FormControl(true, {nonNullable: true}),
			'input': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'separation': new FormControl('', {nonNullable: true}),
			'output': new FormControl('', {nonNullable: true})
		});
	}
}