import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { ExtractTextFormControl, ExtractTextFormModel } from './interfaces/form-control';

@Component({
	selector: 'app-extract',
	templateUrl: './view.component.html'
})
export class ExtractTextComponent extends CToolAction<FormGroup<ExtractTextFormControl>> {
	localStorageKey: string = 'extractForm';
	form: FormGroup<ExtractTextFormControl>;

	private getDefaultData(): ExtractTextFormModel{
		return {
			'isDuplicate': false,
			'isTrim': true,
			'input': 'Just go to <a href="http://google.com">Google</a> to search',
			'start': 'href="',
			'end': '"',
			'output': '',
			'type': 'keep'
		};
	}
	initFormData(): void {
		var localStorageData: ExtractTextFormModel | undefined = this.commonService.myParseJson<ExtractTextFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: ExtractTextFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			var strResult = '';
			var listData = formModelObj.input.split('\n');
			listData = this.commonService.removeEmptyLine(listData);
			listData.forEach(curListData => {
				var startPos = formModelObj.start == '' ? 0 : curListData.toString().indexOf(formModelObj.start);
				var startLength = formModelObj.start == '' ? 0 : formModelObj.start.toString().length;
				if(startPos >= 0){
					var endPos = formModelObj.end == '' ? curListData.toString().length : curListData.toString().indexOf(formModelObj.end, startPos + startLength);
					if(endPos >= 0){
						var subData = curListData.toString().substring(startPos + startLength, endPos);
						if(formModelObj.type == 'keepAndPlus' || formModelObj.type == 'removeAndPlus'){
							subData = formModelObj.start + subData + formModelObj.end;
						}
						if(formModelObj.start != '' || formModelObj.end != ''){
							if(formModelObj.type == 'remove' || formModelObj.type == 'removeAndPlus'){
								subData = curListData.toString().replace(subData, '');
							}
						}
						if(subData !== ''){
							strResult += (strResult !== '' ? '\n' : '') + subData;
						}
					}
				}
			});

			if(formModelObj.isTrim){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.trimDataObjs(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}
			if(formModelObj.isDuplicate){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.removeDuplicates(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}

			this.form.patchValue({output: strResult});
			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_extract_text_title',
			'description': 'lang_cms_text_extract_text_description',
			'image': 'lang_cms_text_extract_text_image'
		});
		this.formChanged();
		this.initFormData();
	}
	constructor() {
		super();
		this.form = new FormGroup<ExtractTextFormControl>({
			'isDuplicate': new FormControl(false, {nonNullable: true}),
			'isTrim': new FormControl(true, {nonNullable: true}),
			'input': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'start': new FormControl('', {nonNullable: true}),
			'end': new FormControl('', {nonNullable: true}),
			'type': new FormControl('keep', {nonNullable: true}),
			'output': new FormControl('', {nonNullable: true}),
		});
	}
}