import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CacheMechanism, LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { TranslateService } from '@ngx-translate/core';
import { COTAppendComponent } from './append-tool/controller.component';
import { CopyFileComponent } from './copy-file-tool/controller.component';
import { CreateNumericComponent } from './create-numeric-tool/controller.component';
import { CreateSlugComponent } from './create-slug-tool/controller.component';
import { CutComponent } from './cut-tool/controller.component';
import { DuplicateComponent } from './duplicate-tool/controller.component';
import { ExtractTextComponent } from './extract-text-tool/controller.component';
import { FilterComponent } from './filter-tool/controller.component';
import { KeepDuplicateComponent } from './keep-duplicate-tool/controller.component';
import { MergeLineComponent } from './merge-line-tool/controller.component';
import { MergeTextComponent } from './merge-text-tool/controller.component';
import { NotFoundPageComponent } from './not-found/controller.component';
import { ReplaceTextComponent } from './replace-text-tool/controller.component';
import { ReverseTextComponent } from './reverse-text-tool/controller.component';
import { SortTextComponent } from './sort-text-tool/controller.component';
import { SplitFileComponent } from './split-file-tool/controller.component';
import { SplitLineComponent } from './split-line-tool/controller.component';
import { SwapTextComponent } from './swap-text-tool/controller.component';
import { UpperLowerComponent } from './upper-lower-tool/controller.component';


const routes: Routes = [
	{ path: '', component: CutComponent },
	{ path: 'cut-tool', component: CutComponent },
	{ path: 'append-tool', component: COTAppendComponent },
	{ path: 'filter-tool', component: FilterComponent },
	{ path: 'duplicate-tool', component: DuplicateComponent },
	{ path: 'keep-duplicate-tool', component: KeepDuplicateComponent },

	{ path: 'merge-text-tool', component: MergeTextComponent },
	{ path: 'split-line-tool', component: SplitLineComponent },
	{ path: 'merge-line-tool', component: MergeLineComponent },
	{ path: 'extract-text-tool', component: ExtractTextComponent },
	{ path: 'sort-text-tool', component: SortTextComponent },
	{ path: 'swap-text-tool', component: SwapTextComponent },
	{ path: 'copy-file-tool', component: CopyFileComponent },
	{ path: 'split-file-tool', component: SplitFileComponent },

	{ path: 'create-numeric-tool', component: CreateNumericComponent },
	{ path: 'create-slug-tool', component: CreateSlugComponent },
	{ path: 'reverse-text-tool', component: ReverseTextComponent },
	{ path: 'upper-lower-tool', component: UpperLowerComponent },
	{ path: 'replace-text-tool', component: ReplaceTextComponent },

	{ path: '**', component: NotFoundPageComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
		LocalizeRouterModule.forRoot(routes, {
			parser: {
              provide: LocalizeParser,
              useFactory: HttpLoaderFactory,
              deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
            },
            cacheMechanism: CacheMechanism.Cookie,
			cookieFormat: '{{value}};{{expires:1800}};path=/'
		})
	],
	exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule { }

export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient): LocalizeRouterHttpLoader {
	return new LocalizeRouterHttpLoader(translate, location, settings, http);
}