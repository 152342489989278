<div class='body_content'>
	<form [formGroup]="form" class="form-horizontal">
		<div class='row'>
			<div class='col-sm-6'>
				<div class="form-group">
					<label class="col-sm-12 control-label">{{'lang_cms_text_create_numeric_start' | mytranslate}}</label>
					<div class="col-sm-12">
						<input type='number' class="form-control" formControlName='start'>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-12 control-label">{{'lang_cms_text_create_numeric_end' | mytranslate}}</label>
					<div class="col-sm-12">
						<input type='number' class="form-control" formControlName='end'>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<label class="checkbox-inline">
							<input type='checkbox' formControlName='isDesc'><span>&nbsp;{{'lang_cms_text_is_desc' | mytranslate}}</span> 
						</label>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<span><a class='clickable label label-warning text-white' (click)="resetForm()">{{'lang_cms_text_reset_form' | mytranslate}}</a></span> 
					</div>
				</div>
			</div>
			<div class='col-sm-6'>
				<div class="form-group">
					<label class="col-sm-12 control-label">{{'lang_cms_text_output' | mytranslate}}</label>
					<div class="col-sm-12">
						<textarea style='height:102px;white-space:pre;overflow-wrap:normal;' class="form-control" formControlName='output'></textarea>
						<div>
								<span (click)='copyEvent()' class='clickable text-primary'>
								{{'lang_cms_text_copy_to_clipboard' | mytranslate}}
							</span>
								<span *ngIf='isCopied'>: 
								<span class='text-success'>
									<i class="fa fa-check-circle" aria-hidden="true"></i> {{'lang_cms_text_isCopied' | mytranslate}}
								</span>
							</span> | 
							<i>
								{{'lang_cms_text_character_count' | mytranslate}}: 
								{{ form.controls.output.value | charCounter }} | 
									{{'lang_cms_text_word_count' | mytranslate}}: {{ form.controls.output.value | wordCounter }} | 
									{{'lang_cms_text_line_count' | mytranslate}}: {{ form.controls.output.value | lineCounter }}
							</i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>