import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { CreateNumericFormControl, CreateNumericFormModel } from './interfaces/form-control';

export const identityRevealedValidator: ValidatorFn = (Control: AbstractControl): ValidationErrors | null => {
	const control = Control as FormGroup<CreateNumericFormControl>;
	const start = control.controls.start;
	const end = control.controls.end;
	if(start.value !== null && end.value !== null &&
		start.value <= end.value){
		end.setErrors(null);
		return null;
	}else{
		end.setErrors({ identityRevealedValidator: true });
		return {'identityRevealed': true};
	}
};

@Component({
	selector: 'app-create-numeric',
	templateUrl: './view.component.html'
})
export class CreateNumericComponent extends CToolAction<FormGroup<CreateNumericFormControl>> {
	localStorageKey: string = 'createNumericForm';
	form: FormGroup<CreateNumericFormControl>;

	private getDefaultData(): CreateNumericFormModel{
		return {
			'isDesc': false,
			'start': 0,
			'end': 10,
			'output': ''
		};
	}
	initFormData(): void {
		var localStorageData: CreateNumericFormModel | undefined = this.commonService.myParseJson<CreateNumericFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: CreateNumericFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			var strResult = '';
			var start = formModelObj.start;
			var end = formModelObj.end;

			if(formModelObj.isDesc){
				for(var i = end; i >= start; i--){
					strResult += (strResult !== '' ? '\n' : '') + i;
				}
			}else{
				for(var i = start; i < end; i++){
					strResult += (strResult !== '' ? '\n' : '') + i;
				}
			}

			this.form.patchValue({output: strResult});
			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_create_numeric_title',
			'description': 'lang_cms_text_create_numeric_description',
			'image': 'lang_cms_text_create_numeric_image'
		});

		this.formChanged();
		this.initFormData();
	}
	constructor() {
		super();
		this.form = new FormGroup<CreateNumericFormControl>({
			'isDesc': new FormControl(false, {nonNullable: true}),
			'start': new FormControl(1, {nonNullable: true, validators: [Validators.required, Validators.min(0)]}),
			'end': new FormControl(10, {nonNullable: true, validators: [Validators.required, Validators.min(0)]}),
			'output': new FormControl('', {nonNullable: true})
		}, { validators: identityRevealedValidator });
	}
}