import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SAztTranslateService } from './translate-service/saz-translate.service';

@Injectable({
	providedIn: 'root'
})
export class CommonService {
	actCopyCommand(content: string): void{
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = content;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

	//Text utils
	trimDataObjs(listData: string[]): string[] {
		return listData.map(e => e.toString().trim());
	}
	removeDuplicates(listData: string[]): string[] {
		var newDataObjs: string[] = [];
		listData.forEach(curData => {
			if(!newDataObjs.includes(curData)){
				newDataObjs.push(curData);
			}
		});
		return newDataObjs;
	}
	removeEmptyLine(listData: string[]): string[]{
		var newDataObjs: string[] = [];
		listData.forEach(curData => {
			if(curData.toString().trim() !== ''){
				newDataObjs.push(curData);
			}
		});
		return newDataObjs;
	}
	countWords(str: string): number {
		return str.trim().split(/\s+/).length;
	}

	//LocalStorage utils
	saveStorage(key: string, value: string): void{
		try{
			if (typeof(Storage) !== 'undefined') {
				localStorage.setItem(key, value);
			}
		}catch{
			localStorage.clear();
		}
	}
	getStorage(key: string): any{
		try{
			if (typeof(Storage) !== 'undefined') {
				return localStorage.getItem(key);
			}
		}catch{
			localStorage.clear();
		}
		return null;
	}
	removeStorage(key: string): void{
		try{
			if (typeof(Storage) !== 'undefined') {
				localStorage.removeItem(key);
			}
		}catch{
			localStorage.clear();
		}
	}

	//Json utils
	serializeJson<T>(data: T): string {
		return JSON.stringify(data);
	}
	isJson(str: string): boolean {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
	myClone<T>(obj: T): T {
		try {
			return JSON.parse(JSON.stringify(obj)) as T;
		} catch {
			return {} as T;
		}
	}
	castObj<T>(source?: object): T | undefined {
		if (source) {
			var jsonStr = this.serializeJson(source);
			return this.castJsonToObj(jsonStr);
		} else {
			return undefined;
		}
	}
	castJsonToObj<T>(jsonStr: string): T | undefined {
		try {
			if (jsonStr) {
				var castDataObj = JSON.parse(jsonStr);
				var castResultData = castDataObj as T;
				return castResultData;
			} else {
				return undefined;
			}
		} catch (ex) {
			return undefined;
		}
	}
	myParseJson<T>(data: string): T | undefined {
		try {
			return data ? (JSON.parse(data) as T) : undefined;
		} catch (ex) {
			return undefined;
		}
	}

	//Translate utils
	getTranslate(key: string): Observable<string> {
		return this.sAZTranslateService.getTranslate(key);
	}
	myTranslate(ArrObjs: Array<string>): Observable<Record<string, string>> {
		return this.sAZTranslateService.myTranslate(ArrObjs);
	}
	myTranslateInstant(value?: string): string | undefined {
		return this.sAZTranslateService.myTranslateInstant(value);
	}
	translateAllKeyOfObj(obj: Record<string, string>): any {
		return this.sAZTranslateService.translateAllKeyOfObj(obj);
	}
	translateToKeyParam(langKey: string, ...params: Array<string>): string {
		return this.sAZTranslateService.translateToKeyParam(langKey, ...params);
	}
	translateMetaData(obj: Record<string, string>): void {
		return this.sAZTranslateService.translateMetaData(obj);
	}

	//String utils
	removeMultipleSpace(str: string): string {
		str = str.replace(/  +/g, ' ');
		return str;
	}
	replaceAll(target: string, search: string, replacement: string): string{
		var escapeCharacters = [')', '(', '*', '[', ']', '{', '}', '|', '^', '<', '>', '\\', '?', '+', '=', '.'];
		var specialRegex = new RegExp( '(\\' + escapeCharacters.join('|\\') + ')', 'g' );
		var search = search.replace(specialRegex, '\\$1');
		if(typeof target != 'undefined'){
			target = target.replace(new RegExp(search, 'g'), replacement);
		}
		return target;
	}


	//Listen message
	private messageSource = new BehaviorSubject(false);
	currentMessage = this.messageSource.asObservable();
	changeMessage(message: boolean) : void {
		this.messageSource.next(message);
	}

	//Angular life circle
	constructor(
		private sAZTranslateService: SAztTranslateService) { }
}