import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { DuplicateFormControl, DuplicateFormModel } from './interfaces/form-control';

@Component({
	selector: 'app-duplicate',
	templateUrl: './view.component.html'
})
export class DuplicateComponent extends CToolAction<FormGroup<DuplicateFormControl>> {
	localStorageKey: string = 'duplicateForm';
	form: FormGroup<DuplicateFormControl>;

	private getDefaultData(): DuplicateFormModel{
		return {
			'isDuplicate': false,
			'isTrim': true,
			'input': 'one\ntwo\nthree',
			'secondInput': 'one\ntwo',
			'type': 'remove',
			'output': ''
		};
	}
	initFormData(): void {
		var localStorageData: DuplicateFormModel | undefined = this.commonService.myParseJson<DuplicateFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: DuplicateFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			var strResult = '';
			var arrObjs1 = formModelObj.input.split('\n');
			arrObjs1 = this.commonService.removeEmptyLine(arrObjs1);
			var arrObjs2 = formModelObj.secondInput.split('\n');
			arrObjs2 = this.commonService.removeEmptyLine(arrObjs2);

			arrObjs1.forEach(subStr => {
				var foundObj = arrObjs2.find(e => e == subStr);
				if(formModelObj.type == 'keep' && foundObj){
					strResult += (strResult !== '' ? '\n' : '') + subStr;
				}else if(!foundObj){
					strResult += (strResult !== '' ? '\n' : '') + subStr;
				}
			});

			if(formModelObj.type != 'keep' && strResult == ''){
				arrObjs2.forEach(subStr => {
					var foundObj = arrObjs1.find(e => e == subStr);
					if(!foundObj){
						strResult += (strResult !== '' ? '\n' : '') + subStr;
					}
				});
			}

			if(formModelObj.isTrim){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.trimDataObjs(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}
			if(formModelObj.isDuplicate){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.removeDuplicates(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}

			this.form.patchValue({output: strResult});
			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_duplicate_title',
			'description': 'lang_cms_text_duplicate_description',
			'image': 'lang_cms_text_duplicate_image'
		});
		this.formChanged();
		this.initFormData();
	}
	constructor() {
		super();
		this.form = new FormGroup<DuplicateFormControl>({
			'isDuplicate': new FormControl(false, {nonNullable: true}),
			'isTrim': new FormControl(true, {nonNullable: true}),
			'input': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'secondInput': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'type': new FormControl('', {nonNullable: true}),
			'output': new FormControl('', {nonNullable: true})
		});
	}
}