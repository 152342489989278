import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SAztTranslateService {
	constructor(
		private translate: TranslateService,
		private metaService: Meta,
		private titleService: Title) { }
	private globalMetaData: Record<string, string> = {};

	// Translate utils
	myTranslate(ArrObjs: Array<string>): Observable<Record<string, string>> {
		return new Observable(subscriber => {
			var listTranslateKeys: Array<string> = [];
			var listOriginKeyToParams: Record<string, Array<string>> = {};
			var listOriginKeyToKeys: Record<string, string> = {};

			var listReturnTranslates: Record<string, string> = {};
			ArrObjs.forEach(originKey => {
				var splitParams = originKey.split('[SPLIT_PARAM]');
				var splitTranslateKey = splitParams[0] ?? '';
				var splitTranslateParam = splitParams.length > 1 && splitParams[1] ? (this.castJsonToObj<Array<string>>(splitParams[1]) ?? []) : [];

				listTranslateKeys = listTranslateKeys.concat(splitTranslateKey);
				listOriginKeyToKeys[originKey] = splitTranslateKey;
				listOriginKeyToParams[originKey] = splitTranslateParam;
			});

			this.translate.get(listTranslateKeys).subscribe((translations: Record<string, string>) => {
				for (var originTranslateKey in listOriginKeyToKeys) {
					if(listOriginKeyToParams[originTranslateKey] != undefined){
						var curTranslateKey = listOriginKeyToKeys[originTranslateKey];
						var curTranslateParam = listOriginKeyToParams[originTranslateKey];
						if(curTranslateKey && translations[curTranslateKey] != undefined){
							listReturnTranslates[originTranslateKey] = this.replaceParamValueToTranslateKey(translations[curTranslateKey] ?? '', curTranslateParam ?? []);
						}
					}
				}
				subscriber.next(listReturnTranslates);
				subscriber.complete();
			});
		});
	}
	myTranslateInstant(value?: string): string | undefined{
		if(value){
			var originKey = value;
			var splitParams = originKey.split('[SPLIT_PARAM]');
			var splitTranslateKey = splitParams[0] ?? '';
			var splitTranslateParam = splitParams.length > 1 && splitParams[1] ? (this.castJsonToObj<Array<string>>(splitParams[1]) ?? []) : [];
			return this.replaceParamValueToTranslateKey(this.translate.instant(splitTranslateKey), splitTranslateParam);
		}else{
			return value;
		}
	}

	//Other translate utils
	getTranslate(key: string): Observable<string> {
		return this.translate.get(key);
	}
	translateAllKeyOfObj(obj: Record<string, string>): object {
		let newObj: Record<string, string> = {};
		Object.keys(obj).forEach(key => {
			if (key.includes('lang_')) {
				obj[key] = this.myTranslateInstant(obj[key]) ?? '';
			}
		});

		newObj = {...obj};
		return newObj;
	}
	translateToKeyParam(langKey: string, ...params: Array<string>): string{
		if(params.length > 0){
			langKey += '[SPLITPARAM]' + this.serializeJson(params);
		}
		return this.myTranslateInstant(langKey) ?? langKey;
	}
	translateMetaData(obj: Record<string, string>): void {
		Object.entries(obj).forEach(([key, value]) => {
			this.globalMetaData[key] = value;
		});
		Object.entries(this.globalMetaData).forEach(([langKey, value]) => {
			this.metaService.updateTag({ property: 'og:type', name: 'fbtype', content: 'article' });
			this.metaService.updateTag({ itemprop: 'type', name: 'gimage', content: 'website' });
			if (langKey == 'title' && value) {
				this.translate.get(value).subscribe((text: string) => {
					this.titleService.setTitle(this.translate.instant(text));
					this.metaService.updateTag({ property: 'og:title', name: 'fbname', content: this.translate.instant(text) });
					this.metaService.updateTag({ itemprop: 'name', name: 'gname', content: this.translate.instant(text) });
				});
			}
			if (langKey == 'description' && value) {
				this.translate.get(value).subscribe((text: string) => {
					this.metaService.updateTag({ name: 'description', content: this.translate.instant(text) });
					this.metaService.updateTag({ property: 'og:description', name: 'fbdescription', content: this.translate.instant(text) });
					this.metaService.updateTag({ itemprop: 'description', name: 'gdescription', content: this.translate.instant(text) });
				});
			}
			if (langKey == 'image' && value) {
				this.translate.get(value).subscribe((text: string) => {
					var curImage = this.translate.instant(text);
					if (curImage.toString().indexOf('http://') === 0 || curImage.toString().indexOf('https://') === 0) {
						this.metaService.updateTag({ property: 'og:image', name: 'fbimage', content: curImage });
						this.metaService.updateTag({ itemprop: 'image', name: 'gimage', content: curImage });
					} else {
						this.metaService.updateTag({ property: 'og:image', name: 'fbimage', content: curImage });
						this.metaService.updateTag({ itemprop: 'image', name: 'gimage', content: curImage });
					}
				});
			}
		});
	}

	//Private utils
	private replaceParamValueToTranslateKey(translateKey: string, paramValue: Array<string>): string{
		var returnValue = translateKey;
		for(var i = 0; i < paramValue.length; i++){
			if(returnValue.indexOf(`{${i}}`) !== -1){
				returnValue = this.replaceAll(returnValue, `{${i}}`, paramValue[i]) ?? returnValue;
			}else{
				returnValue += paramValue[i];
			}
		}
		return returnValue;
	}
	private serializeJson(object: object): string {
		return JSON.stringify(object);
	}
	private castJsonToObj<T>(jsonStr: string): T | undefined {
		try {
			if (jsonStr) {
				var castDataObj = JSON.parse(jsonStr);
				var castResultData = (castDataObj) as T;
				return castResultData;
			} else {
				return undefined;
			}
		} catch (ex) {
			return undefined;
		}
	}
	private replaceAll(target: string | undefined, search: string, replacement: string | undefined): string | undefined {
		if(replacement != undefined && target != undefined){
			return target?.toString().replace(new RegExp(this.escapeRegExp(search), 'g'), replacement);
		}
		return target;
	}
	private escapeRegExp(str: string): string{
		return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
	}
}