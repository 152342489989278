<div class='body_content'>
	<form [formGroup]="form" class="form-horizontal">
		<div class='row'>
			<div class='col-sm-6'>
				<div class="form-group">
					<label class="col-sm-12 control-label">{{'lang_cms_text_input' | mytranslate}}</label>
					<div class="col-sm-12">
						<textarea style='height:150px;white-space:pre;overflow-wrap:normal;' class="form-control" formControlName='input'></textarea>
						<div>
							<i>{{'lang_cms_text_character_count' | mytranslate}}: {{ form.controls.input.value | charCounter }} | 
							{{'lang_cms_text_word_count' | mytranslate}}: {{ form.controls.input.value | wordCounter }} | 
							{{'lang_cms_text_line_count' | mytranslate}}: {{ form.controls.input.value | lineCounter }}</i>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<label class="radio-inline">
							<input type='radio' formControlName='type' value='sentence'><span>&nbsp;{{'lang_cms_text_upper_lower_sentence' | mytranslate}}</span> 
						</label>
						<label class="radio-inline">
							<input type='radio' formControlName='type' value='lower'><span>&nbsp;{{'lang_cms_text_upper_lower_lower' | mytranslate}}</span> 
						</label>
						<label class="radio-inline">
							<input type='radio' formControlName='type' value='upper'><span>&nbsp;{{'lang_cms_text_upper_lower_upper' | mytranslate}}</span> 
						</label>
						<label class="radio-inline">
							<input type='radio' formControlName='type' value='capitalized'><span>&nbsp;{{'lang_cms_text_upper_lower_capitalized' | mytranslate}}</span> 
						</label>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<label class="checkbox-inline">
							<input type='checkbox' formControlName='isDuplicate'><span>&nbsp;{{'lang_cms_text_remove_duplicate' | mytranslate}}</span> 
						</label>
						<label class="checkbox-inline">
							<input type='checkbox' formControlName='isTrim'><span>&nbsp;{{'lang_cms_text_remove_empty' | mytranslate}}</span> 
						</label>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<span><a class='clickable label label-warning text-white' (click)="resetForm()">{{'lang_cms_text_reset_form' | mytranslate}}</a></span> 
					</div>
				</div>
			</div>
			<div class='col-sm-6'>
				<div class="form-group">
					<label class="col-sm-12 control-label">{{'lang_cms_text_output' | mytranslate}}</label>
					<div class="col-sm-12">
						<textarea style='height:150px;white-space:pre;overflow-wrap:normal;' class="form-control" formControlName='output'></textarea>
						<div>
							<span (click)='copyEvent()' class='clickable text-primary'>{{'lang_cms_text_copy_to_clipboard' | mytranslate}}</span>
							<span *ngIf='isCopied'>: <span class='text-success'><i class="fa fa-check-circle" aria-hidden="true"></i> {{'lang_cms_text_isCopied' | mytranslate}}</span></span> | 
							<i>{{'lang_cms_text_character_count' | mytranslate}}: {{ form.controls.output.value | charCounter }} | 
							{{'lang_cms_text_word_count' | mytranslate}}: {{ form.controls.output.value | wordCounter }} | 
							{{'lang_cms_text_line_count' | mytranslate}}: {{ form.controls.output.value | lineCounter }}</i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>