import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundPageComponent } from './not-found/controller.component';

// import ngx-translate and the http loader
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from './../environments/environment';

import { COTAppendComponent } from './append-tool/controller.component';
import { CopyFileComponent } from './copy-file-tool/controller.component';
import { CreateNumericComponent } from './create-numeric-tool/controller.component';
import { CreateSlugComponent } from './create-slug-tool/controller.component';
import { CutComponent } from './cut-tool/controller.component';
import { DuplicateComponent } from './duplicate-tool/controller.component';
import { ExtractTextComponent } from './extract-text-tool/controller.component';
import { FilterComponent } from './filter-tool/controller.component';
import { KeepDuplicateComponent } from './keep-duplicate-tool/controller.component';
import { MergeLineComponent } from './merge-line-tool/controller.component';
import { MergeTextComponent } from './merge-text-tool/controller.component';
import { ReplaceTextComponent } from './replace-text-tool/controller.component';
import { ReverseTextComponent } from './reverse-text-tool/controller.component';
import { SortTextComponent } from './sort-text-tool/controller.component';
import { SplitFileComponent } from './split-file-tool/controller.component';
import { SplitLineComponent } from './split-line-tool/controller.component';
import { SwapTextComponent } from './swap-text-tool/controller.component';
import { UpperLowerComponent } from './upper-lower-tool/controller.component';
import { CustomErrorHandler } from './w-core/custom-error-handler';
import { MyMaterialModule } from './w-core/material.module';
import { CharCounterPipe, LineCounterPipe, MytranslatePipe, WordCounterPipe } from './w-core/pipes/common.pipe';

@NgModule({
	declarations: [
		AppComponent,
		NotFoundPageComponent,

		CutComponent,
		COTAppendComponent,
		FilterComponent,
		DuplicateComponent,
		MergeTextComponent,
		MergeLineComponent,
		SplitLineComponent,
		ExtractTextComponent,
		SortTextComponent,
		SwapTextComponent,
		CopyFileComponent,
		SplitFileComponent,
		CreateNumericComponent,
		CreateSlugComponent,
		ReverseTextComponent,
		UpperLowerComponent,
		ReplaceTextComponent,
		KeepDuplicateComponent,

		WordCounterPipe,
		LineCounterPipe,
		CharCounterPipe,
		MytranslatePipe
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		MyMaterialModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
			}
		}),
        BrowserAnimationsModule
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: CustomErrorHandler,
		},
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json?cacheBuster=' + environment.cacheBusterHash);
}