import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { CutFormControl, CutFormModel } from './interfaces/form-control';

export const identityRevealedValidator: ValidatorFn = (Control: AbstractControl): ValidationErrors | null => {
	const control = Control as FormGroup<CutFormControl>;
	const start = control.controls.start;
	const end = control.controls.end;
	if(start.value !== null && end.value !== null &&
		start.value <= end.value){
		end.setErrors(null);
		return null;
	}else{
		end.setErrors({ identityRevealedValidator: true });
		return {'identityRevealed': true};
	}
};

@Component({
	selector: 'app-cut',
	templateUrl: './view.component.html'
})
export class CutComponent extends CToolAction<FormGroup<CutFormControl>> {
	localStorageKey: string = 'cutForm';
	form: FormGroup<CutFormControl>;

	private getDefaultData(): CutFormModel{
		return {
			'isDuplicate': false,
			'isTrim': true,
			'input': 'email1|pass1\nemail2|pass2',
			'separation': '|',
			'start': 0,
			'end': 0,
			'output': ''
		};
	}
	initFormData(): void {
		var localStorageData: CutFormModel | undefined = this.commonService.myParseJson<CutFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: CutFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			var strResult = '';
			var listData = formModelObj.input.split('\n');
			listData = this.commonService.removeEmptyLine(listData);

			listData.forEach(curData => {
				var subStr = '';
				var curStrs = curData.toString().split(formModelObj.separation);
				for(var j = formModelObj.start; j <= formModelObj.end; j++){
					if(curStrs.length > j){
						subStr += (subStr !== '' ? formModelObj.separation: '') + curStrs[j];
					}
				}
				if(subStr !== ''){
					strResult += (strResult !== '' ? '\n' : '') + subStr;
				}
			});
			if(formModelObj.isTrim){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.trimDataObjs(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}
			if(formModelObj.isDuplicate){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.removeDuplicates(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
				console.log(strResultArrObjs);
				console.log('remove duplicate');
			}

			this.form.patchValue({output: strResult});
			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_cut_title',
			'description': 'lang_cms_text_cut_description',
			'image': 'lang_cms_text_cut_image'
		});
		this.formChanged();
		this.initFormData();
	}
	constructor() {
		super();
		this.form = new FormGroup<CutFormControl>({
			'isDuplicate': new FormControl(false, {nonNullable: true}),
			'isTrim': new FormControl(true, {nonNullable: true}),
			'input': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'separation': new FormControl('', {nonNullable: true}),
			'start': new FormControl(0, {nonNullable: true, validators: [Validators.required, Validators.min(0)]}),
			'end': new FormControl(0, {nonNullable: true, validators: [Validators.required, Validators.min(0)]}),
			'output': new FormControl('', {nonNullable: true}),
		}, { validators: identityRevealedValidator });
	}
}