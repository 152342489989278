import { Component, inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/common.service';
import { IAztCommonLoadingStatus } from './interfaces/IAztCommonLoadingStatus';

@Component({ template: '' })
export class MyBaseComponent implements OnInit {
	public subscriptions: Subscription = new Subscription();
	public statusObj: IAztCommonLoadingStatus = { loading: false, errorMessage: '' };
	public commonService = inject(CommonService);

	// Angular lifecycle
	ngOnInit(): void {}
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}