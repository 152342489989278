<div class='main_menu'>
	<a [routerLink]="['/'] | localize" [ngClass]="isHomeRoute() ? 'menu_selected': ''">{{'lang_cms_text_menu_cut_text' | mytranslate}}</a>&nbsp;|&nbsp;
	<a [routerLink]="['/append-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_append_text' | mytranslate}}</a>&nbsp;|&nbsp;
    <a [routerLink]="['/filter-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_filter_text' | mytranslate}}</a>&nbsp;|&nbsp;
    <a [routerLink]="['/duplicate-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_remove_duplicate' | mytranslate}}</a>&nbsp;|&nbsp;
    <a [routerLink]="['/keep-duplicate-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_keep_duplicate' | mytranslate}}</a>&nbsp;|&nbsp;
    
	<a [routerLink]="['/merge-text-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_merge_text' | mytranslate}}</a>&nbsp;|&nbsp;
	<a [routerLink]="['/split-line-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_split_line' | mytranslate}}</a>&nbsp;|&nbsp;
    <a [routerLink]="['/merge-line-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_merge_line' | mytranslate}}</a>&nbsp;|&nbsp;
	
	<a [routerLink]="['/extract-text-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_extract_text' | mytranslate}}</a>&nbsp;|&nbsp;
	<a [routerLink]="['/sort-text-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_sort_text' | mytranslate}}</a>&nbsp;|&nbsp;
	<a [routerLink]="['/swap-text-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_swap_text' | mytranslate}}</a>&nbsp;|&nbsp;
	
	<a [routerLink]="['/create-numeric-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_create_numeric' | mytranslate}}</a>&nbsp;|&nbsp;
	<a [routerLink]="['/create-slug-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_create_slug' | mytranslate}}</a>&nbsp;|&nbsp;
	<a [routerLink]="['/reverse-text-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_reverse_text' | mytranslate}}</a>&nbsp;|&nbsp;
	<a [routerLink]="['/upper-lower-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_upper_lower' | mytranslate}}</a>&nbsp;|&nbsp;
	<a [routerLink]="['/replace-text-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_replace_text' | mytranslate}}</a>&nbsp;|&nbsp;
	
	<a [routerLink]="['/copy-file-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_copy_file' | mytranslate}}</a>&nbsp;|&nbsp;
	<a [routerLink]="['/split-file-tool'] | localize" routerLinkActive="menu_selected">{{'lang_cms_text_menu_split_file' | mytranslate}}</a>
</div>
<div class='main_content'>
	<router-outlet (activate)="onActivate()"></router-outlet>
	<div class='notepad_box' *ngIf="show404Page === false">{{'lang_cms_text_download_notepad' | mytranslate}}: <a rel="nofllow" href='https://notepad-plus-plus.org/downloads' target='_blank'>https://notepad-plus-plus.org/downloads</a></div>
</div>
<div class='language_box' *ngIf="show404Page === false">
	<a class='clickable' [href]="useLanguage('id')"><img src='/assets/flag/id.svg'/> Indonesia</a>&nbsp;
	<a class='clickable' [href]="useLanguage('en')"><img src='/assets/flag/en.svg'/> English</a>&nbsp;
	<a class='clickable' [href]="useLanguage('vi')"><img src='/assets/flag/vi.svg'/> Vietnamese</a>&nbsp;
	<a class='clickable' [href]="useLanguage('zh-cn')"><img src='/assets/flag/cn.svg'/> Chinese</a>&nbsp;
	<a class='clickable' [href]="useLanguage('th')"><img src='/assets/flag/th.svg'/> Thailand</a>
</div>
<div class='main_footer'>© 2020 TextAndTool.com. All rights reserved.</div>