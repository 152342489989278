<div class='body_content'>
	<form [formGroup]="form" class="form-horizontal">
		<div class='row'>
			<div class='col-sm-6'>
				<div class="form-group">
					<label class="col-sm-12 control-label">{{'lang_cms_text_input' | mytranslate}}</label>
					<div class="col-sm-12">
						<textarea style='height:150px;white-space:pre;overflow-wrap:normal;' class="form-control" formControlName='input'></textarea>
						<div>
							<i>{{'lang_cms_text_character_count' | mytranslate}}: {{ form.controls.input.value | charCounter }} | 
							{{'lang_cms_text_word_count' | mytranslate}}: {{ form.controls.input.value | wordCounter }} | 
							{{'lang_cms_text_line_count' | mytranslate}}: {{ form.controls.input.value | lineCounter }}</i>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-12 control-label">{{'lang_cms_text_split_file_number' | mytranslate}}</label>
					<div class="col-sm-12">
						<input type='number' class="form-control" formControlName='number'>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-12 control-label">{{'lang_cms_text_split_file_start' | mytranslate}}</label>
					<div class="col-sm-12">
						<input type='number' class="form-control" formControlName='start'>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-12 control-label">{{'lang_cms_text_split_file_file_extension' | mytranslate}}</label>
					<div class="col-sm-12">
						<input type='text' class="form-control" formControlName='fileExtension'>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-12 control-label">
						{{'lang_cms_text_split_file_file_name' | mytranslate}}:&nbsp;
						<span class='text-danger'><b>{{form.controls.fileName.value}}{{form.controls.start.value}}.{{form.controls.fileExtension.value}}</b></span>
					</label>
					<div class="col-sm-12">
						<input type='text' class="form-control" formControlName='fileName'>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<label class="checkbox-inline">
							<input type='checkbox' formControlName='isDuplicate'><span>&nbsp;{{'lang_cms_text_remove_duplicate' | mytranslate}}</span> 
						</label>
						<label class="checkbox-inline">
							<input type='checkbox' formControlName='isTrim'><span>&nbsp;{{'lang_cms_text_remove_empty' | mytranslate}}</span> 
						</label>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<span><a class='clickable label label-warning text-white' (click)="resetForm()">{{'lang_cms_text_reset_form' | mytranslate}}</a></span> 
					</div>
				</div>
				<br/><mat-divider></mat-divider><br/>
				<div class="form-group">
					<div class="col-sm-12">
						<button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="doAction()">{{'lang_cms_text_start_action' | mytranslate}}</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>