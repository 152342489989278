import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { ReverseTextFormControl, ReverseTextFormModel } from './interfaces/form-control';

@Component({
	selector: 'app-reverse-text',
	templateUrl: './view.component.html'
})
export class ReverseTextComponent extends CToolAction<FormGroup<ReverseTextFormControl>> {
	localStorageKey: string = 'reverseForm';
	form: FormGroup<ReverseTextFormControl>;

	private getDefaultData(): ReverseTextFormModel{
		return {
			'isDuplicate': false,
			'isTrim': true,
			'input': 'A\nB\nC',
			'output': ''
		};
	}
	initFormData(): void {
		var localStorageData: ReverseTextFormModel | undefined = this.commonService.myParseJson<ReverseTextFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: ReverseTextFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			var strResult = '';
			var listData = formModelObj.input.split('\n');
			listData = this.commonService.removeEmptyLine(listData);

			listData.reverse().forEach(subData => {
				strResult += (strResult !== '' ? '\n' : '') + subData;
			});

			if(formModelObj.isTrim){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.trimDataObjs(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}
			if(formModelObj.isDuplicate){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.removeDuplicates(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}

			this.form.patchValue({output: strResult});
			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_reverse_text_title',
			'description': 'lang_cms_text_reverse_text_description',
			'image': 'lang_cms_text_reverse_text_image'
		});
		this.formChanged();
		this.initFormData();
	}
	constructor() {
		super();
		this.form = new FormGroup<ReverseTextFormControl>({
			'isDuplicate': new FormControl(false, {nonNullable: true}),
			'isTrim': new FormControl(true, {nonNullable: true}),
			'input': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'output': new FormControl('', {nonNullable: true})
		});
	}
}