import { FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { MyBaseComponent } from '../supers/my-base.component';

export abstract class CToolAction<CurFormGroup extends FormGroup> extends MyBaseComponent{
    abstract localStorageKey: string;
    abstract form: CurFormGroup;
    protected isCopied: boolean = false;
    protected resetWithDoAction: boolean = true;

    protected copyEvent(): void{
		var formModelObj = this.form.getRawValue();
        console.log(Object.entries(formModelObj));
        for (const [key, value] of Object.entries(formModelObj)) {
            if(key.toLowerCase().indexOf('output') !== -1){
                this.commonService.actCopyCommand(value as string);
                break;
            }
        }
		this.isCopied = true;
		setTimeout(() => {
			this.isCopied = false;
		}, 2000);
	}
    protected formChanged(): void{
        Object.keys(this.form.controls).forEach(key => {
			this.form.get(key)?.valueChanges.pipe(debounceTime(100)).subscribe(value => {
				if(key.toString().toLowerCase().indexOf('output') == -1){
					this.doAction();
				}
			});
		});
    }
    protected resetForm(): void {
        this.commonService.removeStorage(this.localStorageKey);
		this.initFormData();
        if(this.resetWithDoAction){
            this.doAction();
        }
    }

    abstract initFormData(): void;
    abstract doAction(): void;
}