import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { UpperLowerFormControl, UpperLowerFormModel } from './interfaces/form-control';

@Component({
	selector: 'app-upper-lower',
	templateUrl: './view.component.html'
})
export class UpperLowerComponent extends CToolAction<FormGroup<UpperLowerFormControl>> {
	localStorageKey: string = 'upperLowerForm';
	form: FormGroup<UpperLowerFormControl>;

	stringSentenceCase(str: string): string {
		return str.replace(/\.\s+([a-z])[^\.]|^(\s*[a-z])[^\.]/g, s => s.replace(/([a-z])/, s => s.toUpperCase()));
	}
	stringToTitleCase(str: string): string{
		//https://www.digitalocean.com/community/tutorials/js-capitalizing-strings
		return str.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
	}
	private getDefaultData(): UpperLowerFormModel{
		return {
			'isDuplicate': false,
			'isTrim': true,
			'input': 'one two. three\nfour five. six',
			'type': 'sentence',
			'output': ''
		};
	}
	initFormData(): void {
		var localStorageData: UpperLowerFormModel | undefined = this.commonService.myParseJson<UpperLowerFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: UpperLowerFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			var strResult = '';
			var listData = formModelObj.input.split('\n');
			listData = this.commonService.removeEmptyLine(listData);

			listData.forEach(subData => {
				if(formModelObj.type == 'sentence'){
					subData = this.stringSentenceCase(subData);
				}else if(formModelObj.type == 'lower'){
					subData = subData.toLowerCase();
				}else if(formModelObj.type == 'upper'){
					subData = subData.toUpperCase();
				}else if(formModelObj.type == 'capitalized'){
					subData = this.stringToTitleCase(subData);
				}
				strResult += (strResult !== '' ? '\n' : '') + subData;
			});

			if(formModelObj.isTrim){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.trimDataObjs(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}
			console.log(formModelObj);
			if(formModelObj.isDuplicate){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.removeDuplicates(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}

			this.form.patchValue({output: strResult});
			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_upper_lower_title',
			'description': 'lang_cms_text_upper_lower_description',
			'image': 'lang_cms_text_upper_lower_image'
		});
		this.formChanged();
		this.initFormData();
	}
	constructor() {
		super();
		this.form = new FormGroup<UpperLowerFormControl>({
			'isDuplicate': new FormControl(false, {nonNullable: true}),
			'isTrim': new FormControl(true, {nonNullable: true}),
			'input': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'type': new FormControl('sentence', {nonNullable: true, validators: [Validators.required]}),
			'output': new FormControl('', {nonNullable: true})
		});
	}
}