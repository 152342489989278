import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { CreateSlugFormControl, CreateSlugFormModel } from './interfaces/form-control';

@Component({
	selector: 'app-create-slug',
	templateUrl: './view.component.html'
})
export class CreateSlugComponent extends CToolAction<FormGroup<CreateSlugFormControl>> {
	localStorageKey: string = 'createSlugForm';
	form: FormGroup<CreateSlugFormControl>;

	private getDefaultData(): CreateSlugFormModel{
		return {
			'isDuplicate': false,
			'isTrim': true,
			'slugType': 'lower',
			'input': 'small text tools\nthe best text tools',
			'separate': '-',
			'output': ''
		};
	}
	initFormData(): void {
		var localStorageData: CreateSlugFormModel | undefined = this.commonService.myParseJson<CreateSlugFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: CreateSlugFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			var strResult = '';
			var listData = formModelObj.input.split('\n');
			listData = this.commonService.removeEmptyLine(listData);

			listData.forEach(curLineData => {
				curLineData = this.commonService.removeMultipleSpace(curLineData);
				curLineData = this.commonService.replaceAll(curLineData, ' ', formModelObj.separate);
				if(formModelObj.slugType == 'lower'){
					curLineData = curLineData.toLowerCase();
				}else if(formModelObj.slugType == 'upper'){
					curLineData = curLineData.toUpperCase();
				}
				strResult += (strResult !== '' ? '\n' : '') + curLineData;
			});

			if(formModelObj.isTrim){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.trimDataObjs(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}
			if(formModelObj.isDuplicate){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.removeDuplicates(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}

			this.form.patchValue({output: strResult});
			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_create_slug_title',
			'description': 'lang_cms_text__description',
			'image': 'lang_cms_text_create_slug_image'
		});

		this.formChanged();
		this.initFormData();
	}
	constructor() {
		super();
		this.form = new FormGroup<CreateSlugFormControl>({
			'isDuplicate': new FormControl(false, {nonNullable: true}),
			'isTrim': new FormControl(true, {nonNullable: true}),
			'slugType': new FormControl('lower', {nonNullable: true, validators: [Validators.required]}),
			'input': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'separate': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'output': new FormControl('', {nonNullable: true})
		});
	}
}