import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { CopyFileFormControl, CopyFileFormModel } from './interfaces/form-control';

export const identityRevealedValidator: ValidatorFn = (Control: AbstractControl): ValidationErrors | null => {
	const control = Control as FormGroup<CopyFileFormControl>;
	const start = control.controls.start;
	const end = control.controls.end;
	if(start.value !== null && end.value !== null &&
		start.value <= end.value){
		end.setErrors(null);
		return null;
	}else{
		end.setErrors({ identityRevealedValidator: true });
		return {'identityRevealed': true};
	}
};

@Component({
	selector: 'app-copy-file',
	templateUrl: './view.component.html'
})
export class CopyFileComponent extends CToolAction<FormGroup<CopyFileFormControl>> {
	localStorageKey: string = 'copyFileForm';
	form: FormGroup<CopyFileFormControl>;

	private getDefaultData(): CopyFileFormModel{
		return {
			'input': 'Content',
			'start': 1,
			'end': 100,
			'fileExtension': 'txt',
			'fileName': 'name-',
		};
	}
	initFormData(): void {
		var localStorageData: CopyFileFormModel | undefined = this.commonService.myParseJson<CopyFileFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: CopyFileFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			let zip: JSZip = new JSZip();
			for(var i = formModelObj.start; i <= formModelObj.end; i++){
				var fileName = formModelObj.fileName + i + '.' + formModelObj.fileExtension;
				zip.file('copy/' + fileName, formModelObj.input);
			}
			zip.generateAsync({type: 'blob'}).then(function(content){
				FileSaver.saveAs(content, 'copy.zip');
			});

			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_copy_file_title',
			'description': 'lang_cms_text_copy_file_description',
			'image': 'lang_cms_text_copy_file_image'
		});

		this.initFormData();
	}
	constructor() {
		super();
		this.resetWithDoAction = false;
		this.form = new FormGroup<CopyFileFormControl>({
			'input': new FormControl('', {nonNullable: true}),
			'start': new FormControl(1, {nonNullable: true, validators: [Validators.required, Validators.min(1)]}),
			'end': new FormControl(100, {nonNullable: true, validators: [Validators.required, Validators.min(1)]}),
			'fileExtension': new FormControl('', {nonNullable: true}),
			'fileName': new FormControl('', {nonNullable: true})
		}, { validators: identityRevealedValidator });
	}
}