import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CToolAction } from '../w-core/base-tools/IToolAction';
import { ReplaceTextFormControl, ReplaceTextFormModel } from './interfaces/form-control';

@Component({
	selector: 'app-replace-text',
	templateUrl: './view.component.html'
})
export class ReplaceTextComponent extends CToolAction<FormGroup<ReplaceTextFormControl>> {
	localStorageKey: string = 'replaceForm';
	form: FormGroup<ReplaceTextFormControl>;

	private getDefaultData(): ReplaceTextFormModel{
		return {
			'isDuplicate': false,
			'isTrim': true,
			'input': 'from from from\nto1 to1 to1',
			'replace': 'from|to\nto1|to2',
			'output': ''
		};
	}
	initFormData(): void {
		var localStorageData: ReplaceTextFormModel | undefined = this.commonService.myParseJson<ReplaceTextFormModel>(this.commonService.getStorage(this.localStorageKey));
		var initData: ReplaceTextFormModel = localStorageData ?? this.getDefaultData();
		this.form.patchValue(initData);
	}
	doAction(): void {
		if(this.form.valid){
			var formModelObj = this.form.getRawValue();

			var strResult = '';
			var tempData = '__MY_TEMP_DATA__';
			var listData = formModelObj.input.split('\n');
			listData = this.commonService.removeEmptyLine(listData);
			var replaceDataObjs = formModelObj.replace.split('\n');
			replaceDataObjs = this.commonService.removeEmptyLine(replaceDataObjs);

			listData.forEach(subData => {
				replaceDataObjs.forEach(curReplaceData => {
					var curReplaceData = this.commonService.replaceAll(curReplaceData, '\\|', tempData);
					var replaceDataStrObjs = curReplaceData.toString().split('|');
					if(replaceDataStrObjs.length == 2 && replaceDataStrObjs[0] != undefined && replaceDataStrObjs[1] != undefined){
						subData = this.commonService.replaceAll(subData, replaceDataStrObjs[0], replaceDataStrObjs[1]);
						subData = this.commonService.replaceAll(subData, tempData, '|');
					}
				});
				strResult += (strResult !== '' ? '\n' : '') + subData;
			});

			if(formModelObj.isTrim){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.trimDataObjs(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}
			if(formModelObj.isDuplicate){
				var strResultArrObjs = strResult.toString().split('\n');
				strResultArrObjs = this.commonService.removeDuplicates(strResultArrObjs);
				strResult = strResultArrObjs.join('\n');
			}

			this.form.patchValue({output: strResult});
			this.commonService.saveStorage(this.localStorageKey, JSON.stringify(formModelObj));
		}
	}

	//Angular life circle
	ngOnInit(): void {
		this.commonService.translateMetaData({
			'title': 'lang_cms_text_replace_text_title',
			'description': 'lang_cms_text_replace_text_description',
			'image': 'lang_cms_text_replace_text_image'
		});
		this.formChanged();
		this.initFormData();
	}
	constructor() {
		super();
		this.form = new FormGroup<ReplaceTextFormControl>({
			'isDuplicate': new FormControl(false, {nonNullable: true}),
			'isTrim': new FormControl(true, {nonNullable: true}),
			'input': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'replace': new FormControl('', {nonNullable: true, validators: [Validators.required]}),
			'output': new FormControl('', {nonNullable: true})
		});
	}
}